import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import Dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

const FreeSessionModal = ({ open, handleClose, sessionType, userId }) => {
  const [formData, setFormData] = useState({
    selectedDate: null,
    selectedTime: "",
    errorDate: "",
    errorTime: "",
  });
  Dayjs.extend(utc);
  const timeSlots = [];
  for (let hour = 11; hour <= 16; hour++) {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour; // Convert to 12-hour format

    timeSlots.push(`${displayHour}:00 ${period}`);
    timeSlots.push(`${displayHour}:30 ${period}`);
  }
  timeSlots.push("5:00 PM");
  const handleBookAppointment = () => {
    let isValid = true;
    let errors = { errorDate: "", errorTime: "" };

    if (!formData.selectedDate) {
      errors.errorDate = "Please enter date";
      isValid = false;
    }

    if (!formData.selectedTime) {
      errors.errorTime = "Please enter time";
      isValid = false;
    }

    setFormData({ ...formData, ...errors });

    if (isValid) {
      console.log("Booking Details:", {
        date: formData.selectedDate.format("DD/MM/YYYY"),
        time: formData.selectedTime,
      });
      const sendForMdata = {
        partnerUserId: userId,
        sessionClassName: sessionType,
        sessionDate: formData.selectedDate,
        sessionTime: formData.selectedTime,
      };

      AxiosInstance.post("trainer/create-free-session", sendForMdata)
        .then((response) => {
          setFormData({
            selectedDate: null,
            selectedTime: "",
            errorDate: "",
            errorTime: "",
          });
          handleClose();
          Swal.fire({
            title: "Appointment Booked!",
            text: `Your appointment at ${sessionType} is booked on ${formData.selectedDate.format(
              "DD/MM/YYYY"
            )} at ${formData.selectedTime}.`,
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              popup: "swal-popup",
            },
            didOpen: () => {
              const swalPopup = document.querySelector(".swal-popup");
              if (swalPopup) {
                swalPopup.style.zIndex = "999999";
              }
            },
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography id="modal-title" variant="h6">
            Book Free Session
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          textAlign="center"
          p={3}
          boxShadow={3}
          borderRadius={2}
          mt={2}
          border="1px solid"
        >
          <Typography variant="h6" gutterBottom>
            Free Appointment is available only between 11 AM to 5 PM (Monday -
            Saturday)
          </Typography>
          <Typography variant="h5" mb={2}>
            Book Free Appointment for {sessionType}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    formData.selectedDate
                      ? Dayjs(formData.selectedDate).utc()
                      : null
                  }
                  minDate={Dayjs().startOf("day")}
                  onChange={(newDate) =>
                    setFormData({
                      ...formData,
                      selectedDate: newDate
                        ? newDate.utc().startOf("day")
                        : null,
                      errorDate: "",
                    })
                  }
                  format="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </LocalizationProvider>
              {formData.errorDate && (
                <Typography color="error" variant="body2">
                  {formData.errorDate}
                </Typography>
              )}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Select
                value={formData.selectedTime}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    selectedTime: e.target.value,
                    errorTime: "",
                  })
                }
                displayEmpty
                variant="outlined"
                error={!!formData.errorTime}
              >
                <MenuItem value="" disabled>
                  Select Time Slot
                </MenuItem>
                {timeSlots.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
              {formData.errorTime && (
                <Typography color="error" variant="body2">
                  {formData.errorTime}
                </Typography>
              )}
            </Box>
          </Box>
          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 3 }}
            onClick={handleBookAppointment}
          >
            Book
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FreeSessionModal;
