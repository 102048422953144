import React from "react";
import { useEffect, useState } from "react";
import {
  Dialog, DialogTitle
} from "@mui/material";
import { ModalBody } from "reactstrap";
import Dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Swal from "sweetalert2";
import GymDetail from "./GymDetail";
import { useClearableField } from "@mui/x-date-pickers";

const BookGymModel = ({ open, onClose, handleClose, sessionType, userId }) => {
  const [isTrainerModalOpen, setTrainerIsModalOpen] = useState(false);
  const [seeMoreAboutGym, setSeeMoreAboutGym] = useState(false);
  const [activeGym, setActiveGym] = useState("Energyia fitness centre");
  const [gymGeneralInformation, setGymGeneralInformation] = useState("");
  const [trailBooking, setTrailBooking] = useState(false);
  const [membershipBooking, setMembershipBooking] = useState(false);
  const [formData, setFormData] = useState({
    selectedDate: null,
    selectedTime: "",
    errorDate: "",
    errorTime: "",
  });
  const [membershipFormData, setMembershipFormData] = useState({
    selectedPlan: "",
    price: null,
    errors: {
      plan: false,
      price: false,
    },
  });
  const [paymentModal, setPaymentModal] = useState(false);

  // Dummy data for plan duration and price
  const plans = [
    { duration: "1 Month", price: 500 },
    { duration: "3 Months", price: 1200 },
    { duration: "6 Months", price: 2000 },
    { duration: "12 Months", price: 3500 },
  ];

  const [kindOfEquipment, setKindOfEquipment] = useState([]);

  const taxRate = 0.18;
  const tax =
    membershipFormData.price != null &&
    parseInt(membershipFormData.price * taxRate);
  const total =
    membershipFormData.price != null &&
    parseInt(membershipFormData.price + tax);

  Dayjs.extend(utc);
  const timeSlots = [];
  for (let hour = 11; hour <= 16; hour++) {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour; // Convert to 12-hour format

    timeSlots.push(`${displayHour}:00 ${period}`);
    timeSlots.push(`${displayHour}:30 ${period}`);
  }
  timeSlots.push("5:00 PM");

  const trainerInformation = () => {
    setTrainerIsModalOpen(true);
  };

  const handleSeeMoreInfo = () => {
    setSeeMoreAboutGym(true);
  };
  const onCloseSeeMoreInfo = () => {
    setSeeMoreAboutGym(false);
  };
  const onCloseTrainerModel = () => {
    setTrainerIsModalOpen(false);
  };

  const handleGymClick = (gym) => {
    setActiveGym(gym);
  };

  const handleTrailBooking = () => {
    setTrailBooking(true);
    setMembershipBooking(false);
  };

  const handleMembershipBooking = () => {
    setMembershipBooking(true);
    setTrailBooking(false);
  };

  const handlePlanChange = (event) => {
    const selectedValue = event.target.value;

    // Find the corresponding price
    const selectedPlanObj = plans.find(
      (plan) => plan.duration === selectedValue
    );

    // Update state with selected plan, price, and reset errors
    setMembershipFormData((prev) => ({
      ...prev,
      selectedPlan: selectedValue,
      price: selectedPlanObj ? selectedPlanObj.price : "",
      errors: { plan: false, price: false }, // Reset errors when user selects a valid option
    }));
  };

  const handleBookAppointment = () => {
    let isValid = true;
    let errors = { errorDate: "", errorTime: "" };

    if (!formData.selectedDate) {
      errors.errorDate = "Please enter date";
      isValid = false;
    }

    if (!formData.selectedTime) {
      errors.errorTime = "Please enter time";
      isValid = false;
    }

    setFormData({ ...formData, ...errors });

    if (isValid) {
      console.log("Booking Details:", {
        date: formData.selectedDate.format("DD/MM/YYYY"),
        time: formData.selectedTime,
      });
      const sendForMdata = {
        partnerUserId: userId,
        sessionDate: formData.selectedDate,
        sessionTime: formData.selectedTime,
      };

      AxiosInstance.post("gym/create-free-session", sendForMdata)
        .then((response) => {
          setFormData({
            selectedDate: null,
            selectedTime: "",
            errorDate: "",
            errorTime: "",
          });
          onClose();
          Swal.fire({
            title: "Appointment Booked!",
            text: `Your appointment at ${activeGym} is booked for ${formData.selectedDate.format(
              "DD/MM/YYYY"
            )} at ${formData.selectedTime}.`,
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              popup: "swal-popup",
            },
            didOpen: () => {
              const swalPopup = document.querySelector(".swal-popup");
              if (swalPopup) {
                swalPopup.style.zIndex = "999999";
              }
            },
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };
  const handleBookMembership = () => {
    const { selectedPlan, price } = membershipFormData;

    // Validate fields
    const newErrors = {
      plan: !selectedPlan,
      price: !price,
    };

    setMembershipFormData((prev) => ({
      ...prev,
      errors: newErrors,
    }));

    // Proceed only if both fields are filled
    if (selectedPlan && price) {
      setPaymentModal(true);
    }
  };
  useEffect(() => {
    AxiosInstance.get("gym/get-profile")
      .then((response) => {
        setGymGeneralInformation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };

  const handlePayment = () => {
    const formData = {
      amount: total,
      partnerUserId: userId,
      partnerType: "GYM",
      planDuration: membershipFormData.selectedPlan,
    };

    AxiosInstance.post(
      `payment/create-subscription-gym-and-trainer?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        setMembershipFormData({
          selectedPlan: "",
          price: null,
          errors: {
            plan: false,
            price: false,
          },
        });
        setPaymentModal(false);
        onClose();
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  console.log("selected", formData);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        zIndex: 999,
      }}
    >
      {/* <DialogTitle
        sx={{
          backgroundColor: "black",
          color: "white",
          border: "1px solid lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ flexShrink: 0 }}>{activeGym}</p>
        <p style={{ marginRight: "10%" }}>Gym phone number</p>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton> */}
    
      {/* <Modal
        isOpen={paymentModal}
        toggle={() => setPaymentModal(false)}
        centered
        modalClassName="custom-modal"
      >
        <ModalHeader
          style={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid lightgrey",
          }}
          toggle={() => setPaymentModal(false)}
        >
          <Typography variant="h6" component="h2">
            Complete your Payment
          </Typography>
        </ModalHeader>
        <ModalBody className="modal-body-centered">
          <Typography
            variant="h5"
            sx={{ color: "purple", fontWeight: "600", fontSize: "1.75rem" }}
            component="h5"
          >
            Uncurl: Health Services
          </Typography>
          <Typography
            variant="h6"
            component="h6"
          >
            Gym Services
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="h6">Plan Duration:</Typography>
              <Typography variant="h6">Order Value:</Typography>
              <Typography variant="body1">Surcharge:</Typography>
              <Typography variant="body1">Tax (18%):</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="h6">
                {membershipFormData.selectedPlan}
              </Typography>
              <Typography variant="h6">₹{membershipFormData.price}</Typography>
              <Typography variant="body1">₹0</Typography>
              <Typography variant="body1">₹{tax && tax.toFixed(2)}</Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              borderTop: "2px solid lightgrey",
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Total:
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                ₹{total && total.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

      
          <Box
            sx={{
              borderTop: "2px solid lightgrey",
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          />
          <Typography variant="body1" sx={{ mb: 3 }}>
            This amount will be charged to your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#5353e9",
              "&:hover": { backgroundColor: "#c60176" },
            }}
            onClick={handlePayment} 
          >
            Confirm & Pay
          </Button>
        </ModalBody>
      </Modal> */}
    </Dialog>
  );
};

export default BookGymModel;
