import React, { useState } from "react";
import {
  Container,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { MdCheckCircle } from "react-icons/md";

const WellnessSurvey = () => {
  const [formData, setFormData] = useState({
    stressManagementProgram: "",
    wellnessHealthProgram: "",
    centralizedDigitalVault: "",
    personalizedHealthRiskAssessment: "",
    effectiveWellnessProgramReducingDiseases: "",
    wellnessProgramReducesAbsenteeism: "",
    wellnessProgramPreventsLifestyleDiseases: "",
    labServicesImportanceEmployeeWellness: "",
    onPremises: "",
    digital: "",
    counsellingServices: "",
    onDemandWorkshops: "",
    mentalHealthBenefits: "",
    employeeStressorsIdentification: "",
    stressManagementReduction: "",
    centralizedMedicalRecordsStorage: "",
    personalizedWellnessHealthRiskAssessment: "",
    quantifiedBenefitsDashboardMetrics: "",
    measurableAbsenteeismReduction: "",
    corporateWellnessProgramEnhancement: "",
    name: "",
    email: "",
    contactNumber: "",
    company: "",
    location: "",
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let newErrors = {};
    ["name", "email", "contactNumber", "company", "location"].forEach(
      (field) => {
        if (!formData[field].trim()) {
          newErrors[field] = "This field is required";
        }
      }
    );
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}/wellness-health/submit-survey`;
    try {
      const response = await axios.post(apiUrl, formData);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting survey:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{ backgroundColor: "white", width: "100%", minHeight: "100vh" }}
      sx={{ padding: 2 }}
    >
      {submitted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <div>
              <MdCheckCircle size={50} color="green" />
            </div>
            <Typography variant="h6" sx={{ color: "green" }}>
              Your Response has been submitted Successfully.
            </Typography>
          </div>
        </Box>
      ) : (
        <>
          <Typography variant="h5" gutterBottom textAlign={"center"}>
            Wellness & Health Survey
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "black", marginBottom: 2 }}
          >
            SOME FACTS!
          </Typography>
          <Typography variant="body1" sx={{ color: "black", marginBottom: 2 }}>
            • According to recent studies, 92% of workers prioritize emotional
            well-being in the workplace when choosing where they would like to
            work.
          </Typography>
          <Typography variant="body1" sx={{ color: "black", marginBottom: 3 }}>
            • 64% of employees aged 21 to 30 are battling high stress levels.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: 3 }}
          >
            Please respond to the following wellness and health survey
            questions:
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {[
                {
                  name: "stressManagementProgram",
                  question:
                    "1. We have a stress management program that allows us to identify, manage and reduce stress in workplace to prevent burnout and mental issues.",
                },
                {
                  name: "wellnessHealthProgram",
                  question:
                    "2. We have a wellness and health program that fully meets our employees needs.",
                },
              ].map((q) => (
                <Grid item xs={12} sm={6} key={q.name}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                      {q.question}
                    </FormLabel>
                    <RadioGroup
                      row
                      name={q.name}
                      value={formData[q.name]}
                      onChange={handleChange}
                    >
                      {[
                        "Strongly agree",
                        "Agree",
                        "Neutral",
                        "Disagree",
                        "Strongly disagree",
                        "Do not have one",
                      ].map((option) => (
                        <FormControlLabel
                          key={option}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <Typography variant="body1" sx={{ marginY: 2 }}>
              <strong>Note:</strong> If you selected, “Do not have one” above,
              then please skip directly to Q9.
            </Typography>

            <Grid container spacing={2}>
              {[
                {
                  name: "centralizedDigitalVault",
                  question:
                    "3. It will be nice to have a centralized digital vault or wallet wherein all our employees’ medical records and data can be stored with 100% privacy and security so employees can access their records as and when they need it.",
                },
                {
                  name: "personalizedHealthRiskAssessment",
                  question:
                    "4. We have a wellness and health program that accurately assess the health risk of each employee and offers personalized solutions to each employee based on their specific needs.",
                },
                {
                  name: "effectiveWellnessProgramReducingDiseases",
                  question:
                    "5. The wellness and health program at our workplace is effective in reducing lifestyle diseases like obesity, diabetes, hypertension, CVD, cancer as well as mental disorder like anxiety, etc.",
                },
                {
                  name: "wellnessProgramReducesAbsenteeism",
                  question:
                    "6. The wellness program has measurably reduced absenteeism and improved productivity in our organization.",
                },
                {
                  name: "wellnessProgramPreventsLifestyleDiseases",
                  question:
                    "7. The wellness program in our organization prevents lifestyle disease in our employees as well as their families while providing appropriate cure to those who are already suffering from these diseases (Obesity, hypertension, diabetes, CVD, cancer, PCOD, Thyroid, etc).",
                },
              ].map((q) => (
                <Grid item xs={12} sm={6} key={q.name}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                      {q.question}
                    </FormLabel>
                    <RadioGroup
                      row
                      name={q.name}
                      value={formData[q.name]}
                      onChange={handleChange}
                    >
                      {[
                        "Strongly agree",
                        "Agree",
                        "Neutral",
                        "Disagree",
                        "Strongly disagree",
                      ].map((option) => (
                        <FormControlLabel
                          key={option}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <Typography variant="h6" gutterBottom mt={3}>
              8. The Wellness program in our organization has following
              attributes.
            </Typography>

            <Grid container spacing={2}>
              {[
                {
                  name: "onPremises",
                  question: "On-premises",
                },
                {
                  name: "digital",
                  question: "Digital",
                },
                {
                  name: "counsellingServices",
                  question:
                    "Counselling services (dietician, physiotherapist, psychiatrist, etc)",
                },
                {
                  name: "onDemandWorkshops",
                  question: "On demand workshops",
                },
                {
                  name: "labServicesImportanceEmployeeWellness",
                  question: "Lab Services",
                },
                {
                  name: "mentalHealthBenefits",
                  question: "Mental health benefits",
                },
                {
                  name: "employeeStressorsIdentification",
                  question:
                    "Identification of stressors in employees (stressors meaning what may be causing stress)",
                },
                {
                  name: "stressManagementReduction",
                  question: "Stress Management and reduction ",
                },
                {
                  name: "centralizedMedicalRecordsStorage",
                  question: "Centralized Medical records storage",
                },
                {
                  name: "personalizedWellnessHealthRiskAssessment",
                  question:
                    "Personalized wellness & health services based on risk assessment of each employee",
                },
                {
                  name: "quantifiedBenefitsDashboardMetrics",
                  question:
                    "Quantified benefits to employees as well as Corporate (Dashboard of metrics)",
                },
                {
                  name: "measurableAbsenteeismReduction",
                  question:
                    "Measurable reduction in absenteeism and increase in productivity as ROI",
                },
              ].map((attr, index) => (
                <Grid item xs={12} sm={6} key={attr.name}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                      {attr.question}
                    </FormLabel>
                    <RadioGroup
                      row
                      name={attr.name}
                      value={formData[attr.name]}
                      onChange={handleChange}
                    >
                      {[
                        "Very Important",
                        "Important",
                        "Neutral",
                        "Not so important",
                        "Not needed",
                      ].map((option) => (
                        <FormControlLabel
                          key={option}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <Box mt={2}>
              <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                9. We are looking to further improve (or start) our corporate
                wellness and health program for employees and are open to
                talking to wellness providers who can provide us with the
                following. Please elaborate below in a few lines:
              </FormLabel>
              <TextField
                name="corporateWellnessProgramEnhancement"
                fullWidth
                margin="normal"
                onChange={handleChange}
                multiline
                rows={3}
              />
            </Box>

            <Box mt={2}>
              <Grid container spacing={2}>
                {["name", "contactNumber", "email", "company", "location"].map(
                  (field) => (
                    <Grid item xs={12} sm={3} key={field}>
                      <TextField
                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                        name={field}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        error={!!errors[field]}
                        helperText={errors[field]}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>

            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "black",
                marginTop: 4,
                marginBottom: 2,
              }}
            >
              LUCKY DRAW
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "black", marginBottom: 3 }}
            >
              First 100 responses will be put in a lucky draw for the following:
            </Typography>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </>
      )}
    </Container>
  );
};

export default WellnessSurvey;
